<template>
  <div class="go-in">
    <banner title="关于三阅"/>
    <div class="section">
      <!--        <div>面包屑？或许会需要</div>-->
      <div class="content-summary">
        <div style="width: 1200px;display: flex;">

          <div class="summary-right">
            <img src="../../assets/img/aboutus.jpg"/>
          </div>
          <div class="summary-left">
            <div style="">
              <div class="titlediv">
                <p class="title">杭州三阅信息技术有限公司</p>
              </div>
            </div>
            <!--            <p class="eTitle">ABOUT US</p>-->
            <p class="content">
              <!--              <span style="font-family: 微软雅黑;color: rgb(91,155,213);letter-spacing: 0pt;line-height: 141%;font-weight: bold"></span>-->
              杭州三阅信息技术有限公司&nbsp;是集图书馆和物联网产品研发、应用集成、客户服务为一体的高新技术企业，主要目标是为图书馆行业用户提供高质量的应用软件和硬件系统设计、集成和维护服务。
              在发展过程中，公司以技术创新、产品质量和客户服务为核心的整体发展策略，专注于研发具有自主知识产权和创新核心技术的图书馆行业应用软、硬件产品，服务于国民经济信息化建设，
              推动图书馆界软件事业的快速发展。
            </p>
            <p class="content">
              目前我们公司已经自主研制了智慧图书馆系类产品，自助设备的使用使读者借还书无需工作人员参与，减少了读者排队的时间，保护了读者的隐私；节约了人力资源成本，使工作人员从简单、
              重复的劳动中解放出来从而为读者提供更多的其它高层次服务，延长了图书馆的服务时间，提高了图书的周转率，提升了图书馆的形象，符合当今社会技术发展的潮流。
              公司成员均在图书馆行业从事软、硬件开发与系统设计工作多年，由优秀的软、硬件工程系统工程师，一流的软、硬件产品设计和开发团队及管理人员组成。优秀的人员素质和集体的团结努力，
              是我们不断自我学习和创新的动力源泉。
            </p>
          </div>
        </div>
      </div>
      <!--        <div style="background-color: rgb(255, 255, 255);text-align: center">-->
      <!--          <img src="../images/20180412/CorporatePhilosophy.png" style="width: 82%">-->
      <!--        </div>-->

      <!--        <el-divider class="el-divider-active">-->
      <!--          <i class="el-icon-arrow-down el-icon-arrow-down-active"></i>-->
      <!--        </el-divider>-->
      <!-- 发展历程 -->
      <!--        <div class="content-course">-->
      <!--          <div class="top">-->
      <!--            <h3>发展历程</h3>-->
      <!--            <p>DEVELOPMENT</p>-->
      <!--            <div class="border"></div>-->
      <!--            <div class="timeline"></div>-->
      <!--          </div>-->
      <!--          <div class="course-time">-->
      <!--            <swiper :options="swiperOption" ref="mySwiper">-->
      <!--              <swiper-slide v-for="(item,index) in courseList" :key="index">-->
      <!--                <div class="time-show">-->
      <!--                  <div class="time-show-item" v-for="(courseOne,one) in item" :key="one">-->
      <!--                    <div class="item-top" :class="{'order-top' : one%2===1}"></div>-->
      <!--                    <el-divider>-->
      <!--                      <i class="el-icon-mobile-phone"></i>-->
      <!--                    </el-divider>-->
      <!--                    <div class="item-bottom" :class="{'order' : one%2===1}">-->
      <!--                      <div class="item-bottom-content">-->
      <!--                        <p>{{courseOne.Content}}</p>-->
      <!--                        <p>{{courseOne.Year}}</p>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </swiper-slide>-->
      <!--              <div class="swiper-button-prev" slot="button-prev"></div>-->
      <!--              <div class="swiper-button-next" slot="button-next"></div>-->
      <!--            </swiper>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div class="content-culture">-->
      <!--          <h3>企业文化</h3>-->
      <!--          <h3>CULTURE</h3>-->
      <!--          <p>我们只专注一件事情——工程项目管理</p>-->
      <!--          <span>确保工程无重大安全事故</span>-->
      <!--          <span>确保工程施工单位无大面积返工</span>-->
      <!--          <span>确保工程材料及设备无伪劣产品</span>-->
      <!--          <span>确保工程管理留下痕迹、实施过程可追溯</span>-->
      <!--        </div>-->
      <div style="width: 100%;background-color: #f6fbff">
        <div class="corporate_culture">
          <!--          <div class="honor-big-img">-->
          <!--            <el-dialog :title="dialogTitle" :visible.sync="dialogTableVisible">-->
          <!--              <img v-lazy="dialogUrl" alt />-->
          <!--            </el-dialog>-->
          <!--          </div>-->
          <div class="top">
            <p class="titleName">企业文化</p>
            <p style="color: #B1B1B2">CULTURE</p>
            <!--            <div class="border"></div>-->
          </div>
          <div class="culture_box">
<!--            <div id="leftbox">-->
<!--              <img src="../images/20180412/img_1.png">-->
<!--            </div>-->
            <div id="box">
              <div class="item">
                <img src="../../assets/img/img_11.png">
                <p>崇尚创新</p>
                <p>不断探索新的方式，创造更大的价值</p>
              </div>
              <div class="item">
                <img src="../../assets/img/img_9.png">
                <p>尊重个人</p>
                <p>重视每个员工的工作表现和成就</p>
              </div>
              <div class="item" style="margin-right: 0">
                <img src="../../assets/img/login-bg.jpg">
                <p>追求卓越</p>
                <p>致力于为客户提供卓越的产品和服务</p>
              </div>
<!--              <div class="item" style="margin-right: 0">-->
<!--                <img src="../images/20180412/img_2.png">-->
<!--              </div>-->
<!--              <div class="item">-->
<!--                <img src="../images/20180412/img_2.png">-->
<!--              </div>-->
<!--              <div class="item" style="margin-right: 0">-->
<!--                <img src="../images/20180412/img_2.png">-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <!-- 公司荣誉 -->
      <div style="width: 100%;">
        <div class="content-honor">
          <!--          <div class="honor-big-img">-->
          <!--            <el-dialog :title="dialogTitle" :visible.sync="dialogTableVisible">-->
          <!--              <img v-lazy="dialogUrl" alt />-->
          <!--            </el-dialog>-->
          <!--          </div>-->
          <div class="top">
            <p class="titleName">荣誉资质</p>
            <p style="color: #B1B1B2">HONOUR</p>
            <!--            <div class="border"></div>-->
          </div>
          <div class="honor-show">
            <div class="shu_honor" style="">
              <img src="../../assets/img/honor/年审-杭州三阅信息技术有限公司-1.jpg">
              <div>企业信用等级证书</div>
            </div>
            <div class="shu_honor"><img src="../../assets/img/honor/年审-杭州三阅信息技术有限公司-5.jpg">
              <div>AAA级重合同守信用单位</div>
            </div>
            <div class="shu_honor"><img src="../../assets/img/honor/年审-杭州三阅信息技术有限公司-3.jpg">
              <div>AAA级质量服务诚信单位</div>
            </div>
            <div class="shu_honor" style="margin-right: 0"><img
                src="../../assets/img/honor/年审-杭州三阅信息技术有限公司-4.jpg">
              <div>AAA级诚信经营示范单位</div>
            </div>
            <div class="shu_honor"><img src="../../assets/img/honor/年审-杭州三阅信息技术有限公司-6.jpg">
              <div>AAA级诚信供应商</div>
            </div>
            <div class="shu_honor"><img src="../../assets/img/honor/年审-杭州三阅信息技术有限公司-7.jpg">
              <div>中国诚信企业家</div>
            </div>
            <div class="shu_honor"><img src="../../assets/img/honor/年审-杭州三阅信息技术有限公司-2.jpg">
              <div>企业资信等级证书</div>
            </div>
            <div class="shu_honor" style="margin-right: 0"><img
                src="../../assets/img/honor/优秀民营企业.jpg">
              <div>浙江省优秀民营企业</div>
            </div>
            <div class="shu_honor"><img src="../../assets/img/honor/优秀民营企业证书.jpg">
              <div>浙江省优秀民营企业</div>
            </div>
            <div class="heng_honor"><img src="../../assets/img/honor/ISO9001.jpg">
              <div>质量管理体系认证证书</div>
            </div>
            <div class="heng_honor"><img src="../../assets/img/honor/RFID标签转换软件.jpg">
              <div>RFID标签转换软件V1.0</div>
            </div>
            <div class="heng_honor" style="margin-right: 0"><img
                src="../../assets/img/honor/RFID读写软件.jpg">
              <div>RFID读写软件V1.0</div>
            </div>
            <div class="heng_honor"><img src="../../assets/img/honor/RFID馆员工作站软件.jpg">
              <div>RFID馆员工作站软件V1.0</div>
            </div>
            <div class="heng_honor"><img src="../../assets/img/honor/图书馆大数据分析软件V1.0.jpg">
              <div>图书馆大数据分析软件V1.0</div>
            </div>
            <div class="heng_honor"><img src="../../assets/img/honor/文本OCR识别软件V1.0.jpg">
              <div>文本OCR识别软件V1.0</div>
            </div>
            <div class="heng_honor" style="margin-right: 0"><img
                src="../../assets/img/honor/智能盘点软件.jpg">
              <div>智能盘点软件V1.0</div>
            </div>
            <div class="heng_honor"><img src="../../assets/img/honor/红外双目活体检测人脸识别软件.jpg">
              <div>红外双目活体检测人脸识别软件V1.0</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../../components/Banner";

export default {
  components: {
    Banner,
  },
  data() {
    return {
      loading: true,
      honorList: [],
      partnerImg: [],
      courseList: [],
      teamItem: [],
      dialogTableVisible: false,
      dialogUrl: "",
      dialogTitle: ""
    };
  },
  created() {
    this.$nextTick(() => {this.toCurrent()})
  },
  methods:{
    toCurrent(){
      let anchorId = sessionStorage.getItem('anchorId')
      console.log(anchorId)
      if(anchorId){
        if(anchorId === 'CompanyProfile'){
          document.documentElement.scrollTop =0
        }else if(anchorId === 'corporateculture'){
          document.documentElement.scrollTop = 180+80+464
        }else if(anchorId === 'Honorsqualifications'){
          document.documentElement.scrollTop = 180+80+464+575
        }
      }
      sessionStorage.removeItem('anchorId')
    },
  }
};
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}

a {
  text-decoration: none; /* 去除默认的下划线 */
  color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}

* {
  margin: 0;
  padding: 0;
}

.titlediv {
  padding-bottom: 15px;
  animation: titledivkey 2.5s;
  -webkit-animation: titledivkey 2.5s; /* Safari and Chrome */
}



.go-in {

  .section {

    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;

  }

}

.content-summary {
  //height: 500px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding: 70px 0;

  .summary-left {
    width: 700px;
    margin-left: 50px;
    .title {
      position: relative;
      font-size: 26px;
      font-weight: bold;
      color: #0065a4;

      //padding-bottom: 10px;
      //border-bottom: 4px solid rgb(0, 0, 0, 0.7);
      display: inline;
      //animation: title 2.5s;
      //-webkit-animation: title 2.5s;
    }

    .eTitle {
      font-size: 17px;
      color: #e13834;
      padding: 20px 0;
    }

    .content {
      //color: rgb(148, 148, 148);
      text-indent: 2em;
      letter-spacing: 0px;
      line-height: 141%;
      font-family: "Microsoft YaHei";
    }
  }

  .summary-right {
    position: relative;
    //width: 400px;
    //height: 310px;
    //border: 2px solid #1d42b9;
    animation: imgboxkey 2.5s;
    //border-radius: 10px;
    //margin-top: 30px;
    text-align: center;
    //margin-left: 30px;

    img {
      width: 450px;
      height: 300px;
      margin-top: 20px;
      //animation: imgbo 2.5s;
    }
  }

}

//发展历程
.content-course {
  background-color: #f9f8f8;
  padding: 50px 0;

  .course-time {
    width: 1000px;
    height: 400px;
    margin: 20px auto;

    .swiper-container {
      height: 100%;
    }

    .time-show {
      width: 700px;
      height: 100%;
      margin: 0 auto;
      display: flex;

      .time-show-item {
        width: 350px;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .item-top,
        .item-bottom {
          height: 190px;
        }

        .item-bottom {
          // display: flex;
          // align-content: center;
          .item-bottom-content {
            background-color: #1667a0;
            margin: 20px 0;

            p {
              color: #fff;
              text-align: center;
              padding: 15px;
            }
          }
        }
      }
    }
  }
}



//公司荣誉
.content-honor {
  padding: 70px 0;
  width: 1200px;
  margin: 0 auto;

  .top {
    text-align: center;
    .titleName {
      font-size: 2em;
      line-height: 2em;
      letter-spacing: 2px;
      font-weight: bold;
    }
  }
  .honor-show {
    width: 1200px;
    //点击查看更多
    height: 1750px;
    overflow: hidden;
    margin-top: 40px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;

    .shu_honor {
      height: 300px;
      width: 390px;
      display: inline-block;
      //margin-right: 25px;
      text-align: center;

      img {
        height: 270px;
        width: 370px;
        object-fit: contain;
        //box-shadow: 7.7px 6.5px 12px rgb(25 30 42 / 20%);

        //-webkit-transform: perspective(1px) translateZ(0);
        //transform: perspective(1px) translateZ(0);
        ////box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        //-webkit-transition-duration: 0.3s;
        //transition-duration: 0.3s;
        //-webkit-transition-property: box-shadow, transform;
        //transition-property: box-shadow, transform;
      }

      //img:hover, img:focus, img:active {
      //  box-shadow: 20px 20px 10px -10px rgba(25,30,42,0.5);
      //  -webkit-transform: scale(1.05);
      //  transform: scale(1.05);
      //}
    }
    .heng_honor{
      height: 400px;
      width: 290px;
      display: inline-block;
      //margin-right: 25px;
      text-align: center;
      img {
        height: 370px;
        width: 260px;
        object-fit: contain;
      }
    }

    li {
      width: 20%;
      height: 430px;
      list-style: none;

      padding: 10px;

      div {
        width: 250px;

        img {
          padding: 10px;
          width: 240px;
          height: 335px;
          box-shadow: 0 30px 30px -10px rgb(186 186 186 / 40%)
        }

        p {
          margin-top: 38px;
          line-height: 1.5em;
          height: 3em;
          color: #666666;
          width: 100%;
        }
      }

    }
  }



}


//团队风采
.content-team {
  padding: 50px 100px;

  .swiper-img {
    height: 400px;
    background: no-repeat center;
    background-size: cover;
  }
}

.corporate_culture {
  width: 1200px;
  margin: 0 auto;
  padding:70px 0;

  .top {
    text-align: center;
    .titleName {
      font-size: 2em;
      line-height: 2em;
      letter-spacing: 2px;
      font-weight: bold;
    }
  }
  .culture_box {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    //#leftbox {
    //  width: 32%;
    //
    //  img {
    //    height: 515px;
    //    width: 100%;
    //  }
    //}

    #box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 31.66%;
        margin-right: 2.5%;

        img {
          width: 100%;
          height: 240px;
        }
        p:nth-child(2){
          text-align: left;
          color: #45be89;
          font-size: 18px;
          line-height: 2;
          font-weight: bold;
          margin-top: 12px;
        }
        p:nth-child(3){
          line-height: 1.5;
          font-size: 14px;
          color: #707070;
        }
      }
    }
  }
}

//合作伙伴
.content-partner {
  padding: 50px 0;

  .partner-img {
    width: 950px;
    margin: 20px auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
      width: 107px;
      height: 107px;
      list-style: none;
      margin: 10px 25px;

      img {
        width: 100%;
        height: 100%;
        border: 1px solid rgb(194, 218, 235);
      }
    }
  }
}


//@keyframes title {
//  from {
//    left: -150px;
//    color: white
//  }
//  to {
//    left: 0;
//    color: black
//  }
//}
//
//@-webkit-keyframes title /* Safari and Chrome */
//{
//  from {
//    left: -150px;
//    color: white
//  }
//  to {
//    left: 0;
//    color: black
//  }
//}

//@keyframes titledivkey {
//  from {
//    border-color: white
//  }
//  to {
//    border-color: #017cc4
//  }
//}
//
//@-webkit-keyframes titledivkey /* Safari and Chrome */
//{
//  from {
//    border-color: white
//  }
//  to {
//    border-color: #017cc4
//  }
//}

//@keyframes imgboxkey {
//
//  from {
//    left: 50%;
//    border: solid rgb(255, 255, 255) 2px;
//  }
//  to {
//    left: 0;
//    border: solid rgb(29, 66, 185) 2px;
//  }
//}

@keyframes imgbo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.el-divider--horizontal {
  margin: 1px 0;
}


// .swiper-button-disabled {
//   display: none;
// }
.order {
  order: -1;
}

.order-top {
  order: 1;
}

.el-divider {
  background-color: red;
  height: 3px;

  .el-divider__text {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0px;
    color: #fff;
    border: 3px solid red;
  }
}

.el-divider-active {
  background-color: #3c6088;
}

.honor-big-img {
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;
  padding-bottom: 20px;
  padding: 5%;
  //background-color: #14679f;
  .el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;

    .el-dialog__body {
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
}
</style>
